import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InventoryIcon from '@mui/icons-material/Inventory';
// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: 'dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'User',
    path: '/user',
    icon: <PeopleAltIcon />,
    subNav: [
      {
        title: 'Public Users',
        path: 'user',
        icon: <PeopleAltIcon />,
      },
    ],
  },
  {
    title: 'Product',
    path: 'product',
    icon: <StorefrontIcon />,
  },
  {
    title: 'category',
    path: 'category',
    icon: <InventoryIcon />,
  },
  {
    title: 'Slider',
    path: 'slider',
    icon: <ViewCarouselIcon />,
  },
  {
    title: 'Enquiry',
    path: 'enquiry',
    icon: <QuestionAnswerIcon />,
  },
];

export default navConfig;

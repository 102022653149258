import { base64 } from './helperService';

const authService = {
  setUser(user) {
    const encodedData = base64.encode(JSON.stringify(user));
    localStorage.setItem('GetUgAdminLogin', encodedData);
  },
  removeUser() {
    localStorage.removeItem('GetUgAdminLogin');
  },
  user() {
    const _tempData = localStorage.getItem('GetUgAdminLogin');
    if (!_tempData) return null;
    const decodedData = base64.decode(_tempData);
    const user = JSON.parse(decodedData);
    return {
      id: user?.id,
      userName: user?.userName,
      email: user?.email,
      token: user?.token,
    };
  },
};

export { authService };

import { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { styled } from '@mui/material/styles';
import { PageLoading } from '../../providers/SkeletonProvider';
import {
  SideBarAllChatList,
  addDirectMessage,
  selectSidebar,
  setAddUserNotifications,
  setMessageCount,
  setMessageCountList,
} from '../../features/userSlice';
import { routerPath } from '../../config/constants';
import { selectUser } from '../../services/stateReducerService';



const Header = lazy(()=> import('../header'));
const SecondryFooter = lazy(() => import('../footer/Secondryfooter'));
const Footer = lazy(() => import('../footer/footer'));
const Nav = lazy(() => import('../nav'));
const MobileSidebar = lazy(() => import('../chat/MobileSidebar'));
const LoginPage = lazy(() => import('../../pages/LoginPage'));
const HeaderCategoryList = lazy(()=> import('../../sections/header/HeaderCategoryList'));

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));


let connection;

let messageCount = 0;

const connectSignalR = (userId) => {
  const url = `${routerPath.baseChatUrl}${routerPath.chat}?userId=${userId}`;
  const newConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();
  try {
    newConnection
      .start()
      .then(() => {
        console.log('Connection started!');
      })
      .catch(console.error);
  } catch (err) {
    console.log('Error while establishing connection :(', err);
  }
  connection = newConnection;
  return newConnection;
}


export const SendMessage = (data) => {
  if (!connection) {
    console.error('SignalR connection is not initialized.');
    return;
  }
  const productId = parseInt(data.productId, 10);
  connection
  .invoke(
    'SendPrivateMessage',
    data.senderId,
    data.recipientId,
    productId,
    data.message,
    data.msgtyp,
    data.file,
    data.subtype,
    data.fileName
  )
    .catch((error) => {
      console.error(error);
    });
};

export const SendNotification = (data) => {
  if (!connection) {
    console.error('SignalR connection is not initialized.');
    return;
  }
  const productId = parseInt(data.productId, 10);
  connection
    .invoke(
      'SentPrivateNotificationForAdmin',
      data.senderId,
      data.adminUserId,
      productId,
      data.notifyType /*  Notification Type Example Product created */,
      data.title /* Title of the Notification */,
      data.notificationDetail
    )
    .catch((error) => {
      console.error(error);
    });
};

function Index() {
  const sidebar = useSelector(selectSidebar);
  const [open, setOpenNav] = useState(false);
  const [OpenCat, setOpenCat] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const auth = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth?.userId) {
      if (!connection) {
        connectSignalR(auth?.userId);
      }
      connection.on('ReceiveMessage', (senderId, message) => {

        dispatch(setMessageCountList(message.from));
        const conversationState = JSON.parse(localStorage.getItem('chatState'));
        messageCount += 1;
        console.log('messageCount', messageCount);
        dispatch(setMessageCount(messageCount));
        if (conversationState !== null) {
          if (conversationState.productId === message.productId && conversationState.recipientId === message.from) {
            dispatch(
              addDirectMessage({
                type: 'msg',
                message: message.text,
                incoming: message.to === auth.userId,
                outgoing: message.from === auth.userId,
              })
            );
          }
        }
        setTimeout(() => {
          dispatch(SideBarAllChatList());
        },1000)
        
      
      });

      connection.on('ReceiveNotificationForUser', (userId, NotificationValue) => {
        console.log('NotificationValue', NotificationValue);
        dispatch(
          setAddUserNotifications({
            id: NotificationValue.id,
            title: NotificationValue.title,
            description: NotificationValue.description,
            avatar: NotificationValue.avatar,
            type: NotificationValue.type,
            createdAt: NotificationValue.createdAt,
            isUnRead: NotificationValue.isUnRead,
          })
        );
      });
    }
    return () => {
      if (connection) {
        connection.off('ReceiveMessage');
      }
    };
  }, [auth?.userId]);

  useEffect(() => {
    if (sidebar) {
      setOpenSidebar(true);
    } else {
      setOpenSidebar(false);
    }
  }, [sidebar]);

  return (
    <>
      <Suspense fallback={<PageLoading />}>
        <Header openNav={() => setOpenNav(true)} onOpenCatNav={() => setOpenCat(true)} />
        <LoginPage />
        <Nav openNav={open} onCloseNav={() => setOpenNav(false)} />
        <MobileSidebar openSidebar={openSidebar} onCloseSidebar={() => setOpenSidebar(false)} />
        <HeaderCategoryList OpenCat={OpenCat} onCloseCat={() => setOpenCat(false)} />
        <Main sx={{ marginTop: '3em' }}>
          <Outlet />
        </Main>
        <SecondryFooter />
        <Footer sx={{ marginTop: '10px' }} />
      </Suspense>
    </>
  );
}

export default Index;
